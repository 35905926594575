<template>
<div class="body">
  <div class="header">
    <wd-img src="https://tennisimgs.oss-cn-hangzhou.aliyuncs.com/person/%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6%E5%A4%B9/1.png" width="70%"></wd-img>
    <wd-img style="margin-top: 30px" src="https://tennisimgs.oss-cn-hangzhou.aliyuncs.com/person/%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6%E5%A4%B9/2.png" width="80%"></wd-img>
    <p style="font-size: 18px;padding: 3px 12px;border:3px solid #2266f9; color:#2266f9;margin-top: 20px;font-weight: 500">安徽保险业5·15反欺诈知识问答活动</p>
    <p style="font-size: 16px; margin-top: 15px;">主办单位 :  安徽省保险行业协会</p>
  </div>
  <div class="content">
    <p style="font-size: 20px;color: #e07121;font-weight: 600">请选择公司</p>
    <div style="width: 100%;display: flex;flex-direction: column;align-items: center;height: 120px">
      <div class="componey"  @click="show=true">
        <wd-img src="https://tennisimgs.oss-cn-hangzhou.aliyuncs.com/person/%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6%E5%A4%B9/6.png" width="20px" style="padding: 10px"></wd-img>
        <p>{{selectedComponey}}</p>
      </div>
      <wd-radio-group v-model="Pvalue" shape="button" @change="selectPvalue" v-if="selectedComponey!==''" style="margin-top: 15px;  background-color: rgba(255,255,255,0);">
        <wd-radio value="保险从业者">保险从业者</wd-radio>
        <wd-radio value="保险消费者">保险消费者</wd-radio>
      </wd-radio-group>
    </div>
    <!--公司选择面板-->
    <wd-action-sheet v-model="show"  style="height: 50%"  >
      <wd-radio-group v-model="selectedComponey" size="large" style="padding:0 20px" shape="dot" @change="selectComponey">
        <p style="padding: 16px 0;color: #2266f9;font-weight: bold" >财产险公司</p>
        <wd-radio v-for="compony in actions" :key="compony.name" :value="compony.name" style="height: 30px">{{ compony.name }}</wd-radio>
        <p style="padding: 16px 0;color: #2266f9;font-weight: bold" >人身险公司</p>
        <wd-radio v-for="item in actions1" :key="item.name" :value="item.name" style="height: 30px">{{ item.name }}</wd-radio>
      </wd-radio-group>
    </wd-action-sheet>
    <wd-img  src="https://tennisimgs.oss-cn-hangzhou.aliyuncs.com/person/%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6%E5%A4%B9/3.png" width="160" @click="answerQusetion"></wd-img>
    <wd-img style="margin-top: 20px" src="https://tennisimgs.oss-cn-hangzhou.aliyuncs.com/person/%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6%E5%A4%B9/4.png" width="160" @click="$router.push('/Rankings')"></wd-img>
  </div>
</div>
</template>

<script>
/**
 * 图片资源
 * */

import store from "@/store";
export default {
  name: "actions",
  data(){
    return{
      Pvalue:'',//选择消费者类型
      show: false,
      selectedComponey:'',//选择公司
      actions: [
        {
          name: '人保财险'
        }, {
          name: '太保产险'
        },{
          name: '国寿财险'
        },{
          name: '太平财险'
        },{
          name: '国元农险'
        },{
          name: '中国信保'
        },{
          name: '平安产险'
        },{
          name: '天安财险'
        },{
          name: '大地产险'
        }, {
          name: '华安财险',
        }, {
          name: '大家财险',
        }, {
          name: '都邦财险',
        }, {
          name: '渤海财险',
        }, {
          name: '永诚财险',
        }, {
          name: '阳光财险',
        }, {
          name: '华泰财险',
        }, {
          name: '亚太财险',
        }, {
          name: '中银保险',
        }, {
          name: '浙商财险',
        }, {
          name: '紫金财险',
        }, {
          name: '安盛天平',
        }, {
          name: '英大财险',
        }, {
          name: '中华联合',
        }, {
          name: '安诚财险',
        }, {
          name: '长安责任',
        }, {
          name: '永安财险',
        }, {
          name: '恒邦财险',
        }, {
          name: '中煤财险',
        }, {
          name: '泰康在线',
        }, {
          name: '鼎和财险',
        }, {
          name: '国任财险',
        }
      ],
      actions1:[
        {name:'中国人寿'},
        {name:'太保寿险'},
        {name:'太平人寿'},
        {name:'人保寿险'},
        {name:'平安人寿'},
        {name:'新华人寿'},
        {name:'泰康人寿'},
        {name:'平安养老'},
        {name:'合众人寿'},
        {name:'人保健康'},
        {name:'华夏人寿'},
        {name:'民生人寿'},
        {name:'富德生命'},
        {name:'农银人寿'},
        {name:'中荷人寿'},
        {name:'幸福人寿'},
        {name:'阳光人寿'},
        {name:'百年人寿'},
        {name:'长城人寿'},
        {name:'中邮人寿'},
        {name:'太平养老'},
        {name:'和谐健康'},
        {name:'泰康养老'},
        {name:'国华人寿'},
        {name:'交银人寿'},
        {name:'利安人寿'},
        {name:'光大永明'},
        {name:'英大人寿'},
        {name:'大家人寿'},
        {name:'东吴人寿'},
        {name:'华泰人寿'},
        {name:'国寿养老'},
        {name:'建信人寿'},
        {name:'工银安盛'},
        {name:'财信人寿'},
        {name:'中信保诚'},
        {name:'中英人寿'},
        {name:'国联人寿'},
        {name:'北大方正'},
        {name:'招商信诺'},
        {name:'中韩人寿'},
        {name:'北京人寿'},
        {name:'中银三星'},
      ]
    }
  },
  mounted() {
    this.Pvalue= this.$store.state.Pvalue;
    this.selectedComponey= this.$store.state.selectedComponey;
    // 开始时间
    const start = this.$moment('2023-05-17 23:59:00');
    const end = this.$moment();
    // 计算时间差
    const duration = this.$moment.duration(start.diff(end));
    if(duration.asSeconds()<0){
      this.$messageBox.alert('活动已结束')
      return
    }
  },
  methods:{
    selectPvalue(Pvalue){
      store.commit("savePvalue",Pvalue)
    },
    selectComponey(index){
      this.show=false
      store.commit("saveComponey",index)
    },
    answerQusetion(){
      // 开始时间
      const start = this.$moment('2023-05-17 23:59:00');
      const end = this.$moment();
      // 计算时间差
      const duration = this.$moment.duration(start.diff(end));
      if(duration.asSeconds()<0){
        this.$messageBox.alert('活动已结束')
        return
      }
      if(this.selectedComponey===''){
        this.$toast('请选择公司')
        return
      }
      if(this.Pvalue===''){
        this.$toast('请选择保险人员类型')
        return
      }
      window.location.href="https://www.yaoyaola.net/exapi/check_user/10813426?url="+encodeURIComponent("https://www.hannya.org.cn/dist/surveyQuestionnaire")+"&flag=1"
    }
  }
}
</script>

<style lang="scss" scoped>
.body{
  width: 100%;
  height: 100vh;
  background-image: url('https://tennisimgs.oss-cn-hangzhou.aliyuncs.com/person/%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6%E5%A4%B9/background.png ');
  background-size: cover;
  .header{
    text-align: center;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .componey{
      height: 40px;
      width: 50%;
      border-radius: 20px;
      border: 2px #909399 solid;
      background: linear-gradient(to bottom, #ffffff, #cccccc);
      margin-top: 15px;
      display: flex;
      justify-content: left;
      align-items: center;
    }
    .context{
      height: 40%;
    }
  }
}
</style>
