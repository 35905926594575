<template>
<div></div>
</template>

<script>
export default {
  name: "null",
  mounted() {
     this.$router.push('/SurveyQuestionnaire')
  }
}
</script>

<style scoped>

</style>
