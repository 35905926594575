import axios from 'axios';

// 基本配置
const instance = axios.create({
  // baseURL: 'https://chatgpt.everflowing.cn/', // 根据实际情况修改API地址
    baseURL: 'http://localhost:8099/',
  timeout: 20000 // 设置超时时间，单位为ms
});



// 响应拦截器
instance.interceptors.response.use(response => {
  const data = response.data;
  if (data && data.code !== "200") { // 根据接口返回的状态码判断是否有错误
      alert(`Error code ${data.code}: ${data.message}`); // 自定义错误提示
      return Promise.reject(new Error(data.message));
  } else {
      return data;
  }
}, error => {
  console.log(error);
  return Promise.reject(error);
});

export default instance;

