import Vue from 'vue'   //引入Vue
import Router from 'vue-router'  //引入vue-router
import SurveyQuestionnaire from '@/components/SurveyQuestionnaire/SurveyQuestionnaire.vue'  //引入根目录下的Hello.vue组件
import wu from "@/components/null/null.vue"
import actions from "@/components/actions/actions.vue"
import Actions1 from "@/components/actions1/Actions1.vue";
import HBenvelope from "@/components/HBenvelope/HBenvelope.vue";

Vue.use(Router)  //Vue全局使用Router

const router =new Router({
    mode: 'history',
    base:"/dist",
    routes: [              //配置路由，这里是个数组
        {                    //每一个链接都是一个对象
            path: '/actions',         //链接路径
            meta:{
                title:'安徽保险业消费者权益保护知识竞答活动',
            },
            name: 'actions1',     //路由名称，
            component: Actions1   //对应的组件模板
        },
        {                    //每一个链接都是一个对象
            path: '/HBenvelope',         //链接路径
            meta:{
                title:'红包抽奖',
            },
            name: 'HBenvelope',     //路由名称，
            component: HBenvelope   //对应的组件模板
        },
        {                    //每一个链接都是一个对象
            path: '/surveyQuestionnaire',         //链接路径
            meta:{
                title:'安徽保险业消费者权益保护知识竞答活动',
            },
            name: 'SurveyQuestionnaire',     //路由名称，
            component: SurveyQuestionnaire   //对应的组件模板
        },
]
})
router.beforeEach((to, from, next)=>{
    if(to.meta.title){
        document.title=to.meta.title
    }
  return next()
})
export default router
