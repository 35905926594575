import Vue from 'vue'
import App from './App.vue'

import WotDesign from 'wot-design'
import 'wot-design/lib/theme-default/index.css';
//引入
import moment from 'moment';
//挂载到原型
Vue.prototype.$moment = moment ;
//汉化，否则显示日期格式是国外的日期格式
moment.locale("zh-CN");
import router from './router/index'
import store from "./store/index";
Vue.config.productionTip = false
Vue.use(WotDesign)
new Vue({
	router,
    store,
  render: h => h(App),
}).$mount('#app')
