import request from './request'
const baseUrl="https://www.everflowing.cn"

//获取有奖问卷的题目
export function getQuestion(){
    return request({
        url: "/SurveyQuestionnaire/getQuestions",
        method: "get",
    });
}
const yyl="https://www.yaoyaola.net/exapi"
//提交用户答卷
export function getUserScore(params){
    return request({
        url: "/SurveyQuestionnaire/getResultAnswer",
        method: "post",
        data:params
    });
}
//领取现金红包
export function getHB(params){
    let data={
        opneid: params.opneid,
        randomNumber:params.randomNumber,
        id:params.id
    }
    return request({
        url: baseUrl + "/SurveyQuestionnaire/sendHB",
        method: "post",
        data:params
    });
}
//获取龙虎榜排名
export function getRankings(){
    return request({
        url: baseUrl + "/SurveyQuestionnaire/getRankings",
        method: "get",
    });
}
//获取红包账户信息
export function getHBInfo(params){
    return request({
        url:  "/SurveyQuestionnaire/getHBInfo",
        method: "post",
        data:params
    });
}
//获取最近前5名中奖用户信息
export function getZJlist(){
    return request({
        url: "/SurveyQuestionnaire/getZJlist",
        method: "get",
    });
}