<template>
  <div class="roulette-container" >
   <div style="width: 100%;height: 20px;background-color: #af191b;position: absolute;top: 0;left: 0"> </div>
    <p style="margin-top: 30px;font-size: 20px;font-weight: bold;color:#ff0909 ">安徽保险业消费者权益保护知识竞答活动 </p>
    <!-- 转盘包裹 -->
    <div class="rotate">
      <!-- 绘制圆点 -->
      <div
          :class="'circle circle_'+index"
          v-for="(item,index) in circleList"
          :key="index"
          :style="{background:index%2==0?colorCircleFirst:colorCircleSecond}"
      ></div>
      <!-- 转盘图片 -->
      <img
          class="dish"
          src="https://tennisimgs.oss-cn-hangzhou.aliyuncs.com/person/%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6%E5%A4%B9/Layer%201.png"
          :style="{transform:rotate_deg,transition:rotate_transition}"
      />
      <!-- 指针图片 -->
      <img class="pointer" src="https://tennisimgs.oss-cn-hangzhou.aliyuncs.com/person/%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6%E5%A4%B9/%E7%82%B9%E5%87%BB%20%E6%8A%BD%E5%A5%96.png" @click="start" />
    </div>
    <p style="margin-top: 10px">可以抽奖次数:{{$store.state.HBCount}}次</p>
    <div style="margin-top: 360px;width: 80%;" class="list"><p style="font-size: 15px;font-weight: bold;color: #ffd300"> 中奖名单</p>
      <table style="width: 100%;padding:10px;">
        <thead >
        <tr >
          <th class="title">用户信息</th>
          <th class="title">中奖金额</th>
          <th class="title">领取时间</th>
        </tr>
        </thead>
        <tbody style="height:130px">
        <tr v-for="(item,index) in list" >
          <td class="context" style="font-size: 11px;max-width: 60px;white-space:nowrap;overflow: hidden">{{  item.openid}}</td>
          <td class="context" style="font-size: 11px">{{item.envelope/100}}元</td>
          <td class="context" style="font-size: 11px">{{$moment.utc(item.answertime,'YYYY-MM-DD hh:mm:ss').fromNow() }}</td>
        </tr>
        </tbody>
      </table>
    </div>

   <div style="width: 100%;height: 20px;background-color: #af191b;position: absolute;bottom: 0;left: 0">

   </div>
  </div>
</template>

<script>
import {getHB,getHBInfo,getZJlist} from "@/utils/api"
var light_timer; //灯光定时器
export default {
  name: "HBenvelope",
  data() {
    return {
      circleList: [], //原点设置
      colorCircleFirst: "#FE4D32", //圆点颜色
      colorCircleSecond: "#fff", //圆点颜色
      cat: 60, //总共8个扇形区域，每个区域约45度
      isAllowClick: true, //是否能够点击
      rotate_deg: 0, //指针旋转的角度
      rotate_transition: "transform 3s ease-in-out", //初始化选中的过度属性控制
      list:[],
        result:0
    };
  },
  created() {
    this.showcircleList();
    getZJlist().then(res=>{
        console.log(res)
      if(res.data!=='' && res.data!==undefined){
        this.list=res.data
      }
    })
  },

  watch: {},

  mounted() {

  },

  methods: {
    //绘制圆点设置
    showcircleList() {
      let circleList = [];
      for (var i = 0; i < 16; i++) {
        circleList.push(i);
      }
      this.circleList = circleList;
      this.light();
    },
    //闪动效果
    light: function() {
      var that = this;
      clearInterval(light_timer);
      light_timer = setInterval(function() {
        if (that.colorCircleFirst == "#FE4D32") {
          that.colorCircleFirst = "#fff";
          that.colorCircleSecond = "#FE4D32";
        } else {
          that.colorCircleFirst = "#FE4D32";
          that.colorCircleSecond = "#fff";
        }
      }, 300); //设置圆点闪烁的效果
    },

    start() {
      if (this.$store.state.HBCount === 0) {
        this.$toast("今日抽奖次数已达上限！");
        return;
      }
      const start = this.$moment('2024-09-30 18:00:00');
      const end = this.$moment();
      // 计算时间差
      const duration = this.$moment.duration(start.diff(end));
      if(duration.asSeconds()<0){
        this.$messageBox.alert('活动已结束,感谢谢您的参与！')
      }else{
        this.rotating();
      }

    },

    rotating() {
      this.$toast.loading({
        loadingType: 'ring',
        loadingColor:"#af191b"
      })
      if (!this.isAllowClick) return;
      this.isAllowClick = false;
      this.rotate_transition = "transform 5s ease-in-out";
      this.$store.state.HBCount--;
      let balance=false;
      var rand_circle = 5; //默认多旋转5圈
        let param={
            openid: this.$store.state.yylUserInfo.openid,
            token:this.$store.state.token,
            id:this.$store.state.id
        }
        getHBInfo(param).then(res=>{
            this.$toast.close()
            var randomDeg = 360 - (res.data.result+Math.floor(Math.random()*10)) * 60; //当前下标对应的角度    45是总共8个扇形区域，每个区域约45度
            var deg = rand_circle * 360 + randomDeg; //将要旋转的度数  由于是顺时针的转动方向需要用360度来减
            this.rotate_deg = "rotate(" + deg + "deg)";
            this.result=res.data.result;

        })
      var that = this;
//倒计时方法
      setTimeout(()=>{
        this.isAllowClick = true;
        this.rotate_deg = "rotate(" + 0 + "deg)"; //定时器关闭的时候重置角度
        this.rotate_transition = "";
        console.log(this.result)
        if(this.result==='0' || this.result==='' || this.result===undefined){
          that.$toast({
            msg: '很遗憾，谢谢参与~ ',
            duration: 5000
          });
        }else if(this.result==='1'){
          that.$toast({
            msg: '恭喜您，获得0.2元现金~ 预计1-2分钟到账 ',
            duration: 5000
          });
        }else if(this.result==='2'){
          that.$toast({
            msg: '恭喜您，获得1元现金~ 预计1-2分钟到账 ',
            duration: 5000
          });
        }else if(this.result==='3'){
          that.$toast({
            msg: '恭喜您，获得2元现金~ 预计1-2分钟到账',
            duration: 5000
          });
        }else if(this.result==='4'){
          that.$toast({
            msg: '"恭喜您，获得5元现金~ 预计1-2分钟到账！',
            duration: 5000
          });
        }
      }, 3500);
    },


  },

  computed: {}
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 12px;
  line-height: 25px;
  height: 5%;
}
.context{
  line-height: 25px;
}
.button:active{
  color: #909399;
}
.roulette-container {
  width: 100%;
  height: 100vh;
  background-image:url("https://tennisimgs.oss-cn-hangzhou.aliyuncs.com/person/%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6%E5%A4%B9/8.png");
  background-repeat:no-repeat;
  background-size:100% 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.list{
  background-image: url("https://tennisimgs.oss-cn-hangzhou.aliyuncs.com/person/%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6%E5%A4%B9/%E5%9B%BE%E5%B1%82%20259_.png");
  background-size: 100% 100%;
}
.times {
  text-align: center;
  line-height: 0.8rem;
  background: #fff;
}
.rotate {
  width: 310px;
  height: 310px;
  background: #ffbe04;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top:250px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rotate .dish {
  width: 270px;
  height: 270px;
}

.pointer {
  width:80px;
  height: 100px;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 圆点 */
.rotate .circle {
  position: absolute;
  display: block;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background: black;
}

.rotate .circle_0 {
  top: 5px;
  left: 150px;
}

.rotate .circle_1 {
  top: 22.5px;
  left: 80px;
}

.rotate .circle_2 {
  top: 22.5px;
  left: 220px;
}

.rotate .circle_3 {
  top: 75px;
  left:25px;
}

.rotate .circle_4 {
  top: 75px;
  right:25px;
}

.rotate .circle_5 {
  bottom: 5px;
  right: 150px;
}

.rotate .circle_6 {
  bottom: 30px;
  right: 70px;
}

.rotate .circle_7 {
  bottom: 150px;
  right:5px;
}

.rotate .circle_8 {
  bottom: 85px;
  right: 20px;
}

.rotate .circle_9 {
  bottom:75px;
  left: 25px;
}

.rotate .circle_10 {
  bottom:25px;
  left: 80px;
}

.rotate .circle_11 {
  bottom: 152px;
  left:5px;
}

</style>
