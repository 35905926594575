<script>

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Actions1",
    data(){
        return{

        }
    },
    mounted() {

    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    computed: {

    },
    methods:{
        answerQusetion(){
          // 开始时间
          const start = this.$moment('2024-09-30 18:00:00');
          const end = this.$moment();
          // 计算时间差
          const duration = this.$moment.duration(start.diff(end));
          if(duration.asSeconds()<0){
            this.$messageBox.alert('活动已结束,感谢谢您的参与！')
            return
          }else{
            window.location.href="https://www.yaoyaola.net/exapi/check_user/10813426?url="+encodeURIComponent("https://www.everflowing.cn/SurveyQuestionnaire")+"&flag=0"
          }

        }
    }
}
</script>

<template>
<div class="body">
    <wd-img style="margin: 20% 20px 20px 20px; " src="https://activity0419.oss-cn-beijing.aliyuncs.com/%E5%9B%BE%E5%B1%82%201%281%29.png"> </wd-img>
   <p class="title" style="white-space:nowrap; font-weight: bold;color:#ba1621;background:linear-gradient(to right, #ffd583, #fab22a);padding: 2px 10px;margin: 10px 20px 0 20px;border-radius: 50px;max-width: 450px">安徽保险业消费者权益保护知识竞答活动</p>
    <p class="title2" style="margin-top: 20px;white-space:nowrap; color: white;">主办单位 : 安徽省保险行业协会</p>
    <button  class="button" @click="answerQusetion">点击按钮 参与答题</button>
    <wd-img style="width: 100%;position: absolute;bottom: -5px;;left:0;max-width: 675px"  src="https://activity0419.oss-cn-beijing.aliyuncs.com/%E5%9B%BE%E5%B1%82%202%281%29.png">
    </wd-img>
</div>
</template>

<style scoped lang="scss">
@media only screen and (max-width: 374px) {
  /* iphone5 或者更小的尺寸，以 iphone5 的宽度（320px）比例设置 font-size */
  .title,.title2{
    font-size: 16px !important;
  }
}
@media only screen and (min-width: 375px)  {
  /* iphone6/7/8 和 iphone x */
  .title,.title2{
    font-size: 20px !important;
  }
}
.button{
  margin-top: 150px;
  background-image: url("https://activity0419.oss-cn-beijing.aliyuncs.com/%E5%9B%BE%E5%B1%82%203%20%E6%8B%B7%E8%B4%9D%202%281%29.png");
  padding: 10px 15px;
  font-size: 22px;
  font-weight: bold;
  border: 0 ;
  max-width: 250px;
  color:#c8131c ;
  border-radius: 50px;
  background-size: cover;
}

.button:active{
  font-size: 20px;
  padding: 8px 12px;
}
.body{
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 100vh;
  background: linear-gradient(to bottom, #b51721 40%, #cd121b 60%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
}
</style>