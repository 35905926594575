<template>
    <div class="Questionnaire">
        <div v-if="loading" style="width: 100%;height: 100vh;display: flex;justify-content: center;align-items: center">
            <wd-loading></wd-loading>
        </div>
        <div v-else>
            <wd-sticky>
                <p style="background: #ba1621;color: white;line-height: 35px">答题时间{{countdown}}</p>
            </wd-sticky>
            <div v-for="(item,index) in questions" class="questions" v-if="questions.length!==0">
                <div v-if="item.types==='0'" class="question-title">单选题</div>
                <div v-else-if="item.types==='1'" class="question-title">多选题</div>
                <div v-else class="question-title">判断题</div>
                <div class="questioncontext">{{ (index+1)+'.'}}{{item.questioncontext}}</div>
                <wd-checkbox-group style="display: flex;flex-direction: column" shape="circle"  v-model="item.userAnswers" :max="item.types==='1'  ? 4 : 1">
                    <wd-checkbox checked-color="#ba1621" class="option"  v-if="item.types==='2'" value="正确">{{item.a}}</wd-checkbox>
                    <wd-checkbox checked-color="#ba1621" class="option" v-else value="A">A.{{item.a}}</wd-checkbox>
                    <wd-checkbox checked-color="#ba1621" class="option"  v-if="item.types==='2'" value="错误">{{item.b}}</wd-checkbox>
                    <wd-checkbox checked-color="#ba1621" class="option" v-else value="B">B.{{item.b}}</wd-checkbox>
                    <wd-checkbox checked-color="#ba1621" class="option" v-if="item.c!==null" value="C">C.{{item.c}}</wd-checkbox>
                    <wd-checkbox checked-color="#ba1621" class="option" v-if="item.d!==null" value="D">D.{{item.d}}</wd-checkbox>
                </wd-checkbox-group>
              <div v-show="showAnswer">
              <wd-notice-bar
                  color="#34D19D"
                  :scrollable="false"
                  background-color="#f0f9eb">
                正确答案: {{item.answer}}
              </wd-notice-bar>
              </div>
            </div>
            <div style="padding: 10px 20px 40px 20px">
                <wd-button size="large" style="width: 100%;background: #af191b" :disabled="isDisabled" @click="submit">提交答卷</wd-button>
            </div>
            <wd-message-box :show="isShow" :confirmButtonText="confirmButtonText"  @action="confirm"  title="结果">
                <div>您的得分：<span :class="[ sorce<=60 ?  'red': 'green']">{{sorce}}</span> 分</div>
                <div v-if="sorce===100">
                    恭喜您获得一次红包抽奖机会！
                </div>
                <div v-else>
                    很遗憾，未能获得现金红包！
                    （题目全对，即可参加抽奖）
                </div>
            </wd-message-box>
        </div>
    </div>

</template>

<script>
import {getQuestion,getUserScore} from "@/utils/api";
import store from "@/store";
export default {
    name: "SurveyQuestionnaire",
    data(){
        return{
          showAnswer:false,
            questions:[],
            timeRemaining: 600, // 5分钟
            timer: null,
            userAnsWer:'',
            isDisabled:false,
            isShow:false,
            sorce:'',
            randomNumber:'',
            confirmButtonText:'再接再励,继续答题',
            loading:true,
            userAs:'',
            token:''
        }
    },
    mounted() {
        if(this.$route.query.u_openid!=='' && this.$route.query.u_openid!==undefined){
            let userinfo= this.$route.query.u_openid;
            this.$store.state.yylUserInfo.openid=userinfo;
        }
        if(this.$store.state.yylUserInfo.openid==='' || this.$store.state.yylUserInfo.openid===undefined){
            this.$router.push('/actions')
            return
        }
        this.timer = setInterval(() => {
            this.timeRemaining--;
            if (this.timeRemaining <= 0) {
                clearInterval(this.timer);
                this.$toast('时间到！请刷新页面重新答题')
                this.isDisabled=true
            }
        }, 1000)
        getQuestion().then(res=>{
            if(res.flag){
                this.token=res.msg
                this.questions=res.data
                this.loading=false
            }
        })
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    computed: {
        countdown() {
            let minutes = Math.floor(this.timeRemaining / 60);
            let seconds = this.timeRemaining % 60;
            return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
        }
    },
    methods:{
        confirm(confirm){
            if( this.confirmButtonText==='再接再励,继续答题'){
                this.isShow=false;
                clearInterval(this.timer);
                this.$router.push('/surveyQuestionnaire')
            }
            if( this.confirmButtonText==='点击抽取现金红包' && this.sorce===100){
                this.isShow=false;
                clearInterval(this.timer);
                this.$store.state.HBCount=this.$store.state.HBCount+1;
                this.$router.push('/HBenvelope')
            }
        },
        submit(){
            for(let i=0;i<this.questions.length;i++){
                if(this.questions[i].userAnswers==='' || this.questions[i].userAnswers===undefined || this.questions[i].userAnswers===null){
                    this.$toast('第'+(i+1)+'题没有选择答案')
                    return
                }
            }
            this.$messageBox.confirm('现在是否提交答卷？', '',).then(()=>{
                let sorce=100;
                let uAs='';
                for(let i=0;i<this.questions.length;i++){
                    console.log(this.questions[i]);
                    this.questions[i].userAnswer=this.questions[i].userAnswers.sort().join('');
                    uAs+=this.questions[i].userAnswer;
                    console.log(this.questions[i].userAnswer);
                    if(this.questions[i].userAnswer!==this.questions[i].answer){
                        sorce=sorce-20;
                    }
                }
                this.userAs=uAs;
                this.sorce=sorce
                if(sorce===100){
                    this.confirmButtonText='点击抽取现金红包'
                }
                if(sorce<100){
                  this.showAnswer=true;
                }
                let data={
                    openid: this.$store.state.yylUserInfo.openid,
                    nickname: this.$store.state.yylUserInfo.nickname,
                    headimgurl: this.$store.state.yylUserInfo.headimgurl,
                    //公司
                    selectedcomponey:this.$store.state.selectedComponey,
                    //从业类型
                    pvalue:this.$store.state.Pvalue,
                    usetime: 300-this.timeRemaining,
                    sorce:sorce,
                    token:this.token,
                    userAs:this.userAs
                }
                getUserScore(data).then(res=>{
                    this.isDisabled=true
                    if(res.flag){
                        if(res.data.answerCount>1){
                            this.$toast("恭喜您又一次全部答对，很遗憾今日抽奖次数已达上限。");
                            return;
                        }
                        console.log(res)
                        this.$store.state.id=res.data.id;
                        this.$store.state.token=res.data.token;
                        console.log(this.$store.state)
                    }
                    this.isShow=true
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .wd-checkbox__txt{
  display: flex;
  overflow: auto !important;
  white-space:pre-wrap;
}
::v-deep .wd-notice-bar__content{
  text-align: left;
}
.Questionnaire{
  width: 100%;
  .questions{
    margin:  10px;
    background-color: white;
    .question-title{
      text-align: left;
      padding: 10px 10px 0 10px;
      font-size: 12px;
      color: #c4c4c4;
    }
    .questioncontext{
      font-size: 15px;
    }
    .questioncontext{
      text-align: left;
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
    }
    .option{
      text-align: left;
      padding: 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap !important;
    }
  }
}
.red{
  color: red;
  font-size: 20px;
  font-weight: bold;
}
.green{
  color: green;
  font-size: 20px;
  font-weight: bold;
}
</style>
